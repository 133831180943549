import React from 'react';
import './SwybleIntroComponent.css'; 
function SwybleIntroComponent() {
  return (
    <div className="swyble-intro">
      <img src="/swyble-logo.png" alt="Swyble Logo" />
    </div>
  );
}

export default SwybleIntroComponent;