import React from 'react';
import './BottomSection.css';

// Import Font Awesome Components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle, faInfoCircle, faTrophy, faLightbulb, faCheckDouble, faList, faCheckCircle } from '@fortawesome/free-solid-svg-icons';

function BottomSection({ mode, contest }) {
  return (
    <div className="bottom-section">
      <div className="center">

      </div>
    </div>
  );
}
function Icon({ type, active }) {
    return (
      <div className={`icon ${active ? 'active' : ''}`}>
        <FontAwesomeIcon icon={type} />
      </div>
    );
  }
export default BottomSection;