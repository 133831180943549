
// src/components/TopSection.js

import React from 'react';
import './TopSection.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faList, faCheckCircle, faLightbulb } from '@fortawesome/free-solid-svg-icons';

function TopSection({ mode, question }) {
  return (
    <div className="topbar">
      <div className="topbar-section left">
{/*         <img src={window.innerWidth <= 768 ? "/logo2.png" : "/logo.png"} alt="Logo" className="center-logo" /> */}
<img src={ "/logo.png"} alt="Logo" className="center-logo" />
      </div>
      <div className="topbar-section center">
        <h1>{question.category}</h1>
        <h2>{question.tags.join(', ')}</h2>
      </div>
      <div className="topbar-section right">
        <Icon type={faList} active={mode === 2} />
        <Icon type={faCheckCircle} active={mode === 3} />
        <Icon type={faLightbulb} active={mode === 4} />
      </div>
    </div>
  );
}

function Icon({ type, active }) {
  return (
    <div className={`icon ${active ? 'active' : ''}`}>
      <FontAwesomeIcon icon={type} />
    </div>
  );
}

export default TopSection;