import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useParams } from 'react-router-dom';

import database from './firebase';
import { getDatabase,ref, onValue ,set} from 'firebase/database'; // Import modular functions
import TopSection from './components/TopSection';
import BottomSection from './components/BottomSection';
import SwybleIntroComponent from './components/SwybleIntroComponent';
import QuestionComponent from './components/QuestionComponent';
import LeaderBoardComponent from './components/LeaderBoardComponent';
import ComingNextComponent from './components/ComingNextComponent';
import BackgroundMusic from './components/BackgroundMusic';
import './App.css';

function ChannelComponent() {
  const { channelId = '_main' } = useParams();
  const [pageState, setPageState] = useState(null);
console.log(channelId)
  useEffect(() => {
    const db = getDatabase();
    const pageStateRef = ref(db, `rooms/${channelId}`);

    const unsubscribe = onValue(pageStateRef, (snapshot) => {
      setPageState(snapshot.val());
    });

    return () => unsubscribe();
  }, [channelId]);

  useEffect(() => {
    console.log(pageState);
  }, [pageState]);

  if (!pageState) return <div>Loading...</div>;

  const renderComponent = () => {
    switch (pageState.mode) {
      case 0:
        return <SwybleIntroComponent />;
      case 1:
        return <ComingNextComponent contest={pageState.Contest} />;
      case 2:
      case 3:
      case 4:
        return (
          <QuestionComponent
            question={pageState.Question}
            mode={pageState.mode}
          />
        );
      case 5:
        return <LeaderBoardComponent />;
      default:
        return null;
    }
  };

  return (
    <div className="App">
      <TopSection mode={pageState.mode} contest={pageState.Contest}    question={pageState.Question}/>
      {/* <BackgroundMusic mode={pageState.mode} /> */}
      <div className="main-content">
        {renderComponent()}
      </div>
      <BottomSection mode={pageState.mode} contest={pageState.Contest} />
    </div>
  );
}

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/channel/:channelId" element={<ChannelComponent />} />
        <Route path="/" element={<ChannelComponent />} />
      </Routes>
    </Router>
  );
}

export default App;